
import { onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";

export default {
  name: "MeasureUnitTypesSelect",
  props: {
    measure_unit_type_id: {
      type: String,
      default: "",
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:measure_unit_type_id"],

  setup(props) {
    const { t } = useI18n();
    const inputMeasureUnitType = ref({
      loading: false,
      options: [],
      list: [],
    });

    const isMeasureUnitRequired = (value) => {
      if (props.required && !value) {
        return t("rmeasureunittypeid");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "measureUnit",
      isMeasureUnitRequired
    );

    const getMeasureUnitType = () => {
      ApiService.get("/api/measure-unit-types").then(({ data }) => {
        inputMeasureUnitType.value.list = data;
        inputMeasureUnitType.value.options = data;
      });
    };
    const selectMeasureUnitType = (query) => {
      if (query !== "") {
        inputMeasureUnitType.value.loading = true;
        setTimeout(() => {
          inputMeasureUnitType.value.loading = false;
          inputMeasureUnitType.value.options =
            inputMeasureUnitType.value.list.filter(
              (item: Record<string, any>) => {
                return (
                  item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
              }
            );
        }, 200);
      } else {
        inputMeasureUnitType.value.options = [];
      }
    };

    watch(
      () => props.measure_unit_type_id,
      (first) => {
        if (first) {
          element_id.value = first;
        }
      }
    );

    onMounted(() => {
      getMeasureUnitType();
    });

    return {
      element_id,
      inputMeasureUnitType,
      errorMessage,
      getMeasureUnitType,
      selectMeasureUnitType,
    };
  },
};
